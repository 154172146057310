import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			component: () => import('./views/match/index.vue'),
			meta: {parent: true}
		},
		{
			path: '/home/:id',
			component: () => import('./views/match/home/index.vue'),
		},
		{
			path: '/notice/:id',
			component: () => import('./views/match/notice/index.vue'),
		},
		{
			path: '/track/:id',
			component: () => import('./views/match/track/index.vue'),
		},
		{
			path: '/certif/:id',
			component: () => import('./views/match/certificate/index.vue'),
		},
		{
			path: '/cdetail/:mid/:gid/:pid',
			component: () => import('./views/match/certificate/detail.vue'),
		},
		{
			path: '/collar/:id',
			component: () => import('./views/match/collar/index.vue'),
		},
		{
			path: '/collar/search/:id',
			component: () => import('./views/match/collar/search.vue'),
		},
		{
			path: '/collar/player/:id',
			component: () => import('./views/match/collar/player.vue'),
		},
		{
			path: '/score/:id',
			component: () => import('./views/match/score/index.vue'),
		},
		{
			path: '/popular/:id',
			component: () => import('./views/match/popularity/index.vue'),
		},
		{
			path: '/broadcast/:id',
			component: () => import('./views/match/broadcast/index.vue'),
			meta: {parent: true}
		},
		{
			path: '/workerLive/:id',
			component: () => import('./views/match/live/worker.vue'),
			meta: {parent: true}
		},
		{
			path: '/live/:id',
			component: () => import('./views/match/live/index.vue'),
			meta: {parent: true}
		},
		{
			path: '/playerLive/:mid/:gid/:pid',
			component: () => import('./views/match/live/player.vue'),
			meta: {parent: true}
		},
		{
			path: '/livedetail/:id/:gid/:pid',
			component: () => import('./views/match/live/detail.vue'),
			meta: {parent: true}
		},
		{
			path: '/real/:id',
			component: () => import('./views/match/real/index.vue'),
		},
		{
			path: '/sreal/:id',
			component: () => import('./views/match/real/simple.vue'),
		},
		/*工作人员*/
		{
			path: '/worker',
			component: () => import('./views/worker/match.vue'),
			meta: {parent: true}
		},
		{
			path: '/worker/menu/:id',
			component: () => import('./views/worker/menu.vue'),
		},
		/*{
			path: '/worker/broad/:id',
			component: () => import('./views/worker/broadcast.vue'),
		},*/
		{
			path: '/worker/score/:id',
			component: () => import('./views/worker/score.vue'),
		},
		{
			path: '/worker/release/:id',
			component: () => import('./views/worker/release.vue'),
		},
		{
			path: '/worker/comment/:id',
			component: () => import('./views/worker/comment.vue'),
		},
		/*微信支付-押金*/
		{
			path: '/payquery/:id',
			component: () => import('./views/pay/deposit/query.vue'),
		},
		{
			path: '/payorder/:id/:pid',
			component: () => import('./views/pay/deposit/order.vue'),
		},
		{
			path: '/payfail/:id',
			component: () => import('./views/pay/deposit/fail.vue'),
		},
		/*微信支付-押金缴纳*/
		/*{
			path: '/deposit/:id',
			component: () => import('./views/pay/ndeposit/index.vue'),
		},
		{
			path: '/depositInfo/:id/:pid',
			component: () => import('./views/pay/ndeposit/detail.vue'),
		},*/
		{path: '*', redirect: '/', hidden: true}
	]
})
