<template>
  <transition :name="transname">
    <keep-alive><router-view class="cube-view"></router-view></keep-alive>
  </transition>
</template>

<script type="text/ecmascript-6">
  export default {
    computed: {
      transname () {
        return this.parent ? '' : 'page-move'
      }
    },
    props: {
      parent: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">
  .page-move-enter {/*, .page-move-leave-active*/
    transform: translate(100%, 0)
  }
  
  .page-move-enter-active{/*, .page-move-leave-active*/
    transition: transform .3s
  }
  
  .cube-view {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
</style>
