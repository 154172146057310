export function getSession (sessionKey) {
  return sessionStorage.getItem(sessionKey)
}

export function setSession (sessionKey, sessionValue) {
  return sessionStorage.setItem(sessionKey, sessionValue)
}

export function removeSession (sessionKey) {
  return sessionStorage.removeItem(sessionKey)
}

export function getLocal (localKey) {
  return localStorage.getItem(localKey)
}

export function setLocal (localKey, localValue) {
  return localStorage.setItem(localKey, localValue)
}

export function removeLocal (localKey) {
  return localStorage.removeItem(localKey)
}
